import React from 'react';
import { usePayments } from '../../../../hooks/payments/usePayments';
import { PackageGrid } from './components/PackageGrid';

export function PaymentsPage() {
  const { loading, handleCheckout } = usePayments();

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-2xl font-bold mb-6">Purchase Tokens</h2>
        <PackageGrid onPurchase={handleCheckout} loading={loading} />
      </div>
    </div>
  );
} 