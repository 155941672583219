import { useState } from 'react';
import { tokenService } from '../../services/supabase/tokenService';

export function usePayments() {
  const [loading, setLoading] = useState(false);

  const handleCheckout = async (pkg) => {
    try {
      setLoading(true);
      const { url } = await tokenService.createCheckoutSession({
        priceId: pkg.id,
        tokens: pkg.tokens,
        amount: pkg.price
      });
      window.location.href = url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      throw new Error('Failed to initiate checkout. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    handleCheckout
  };
} 