import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthPage } from '../../components/features/auth/AuthPage';
import ProtectedRoute from '../../components/common/ProtectedRoute';
import { PaymentsPage } from '../../components/features/payments/PaymentsPage';
import { PaymentSuccess } from '../../components/features/payments/PaymentSuccess';
import { AboutPage } from '../../pages/About';
import { TermsPage } from '../../pages/Terms';
import { PrivacyPage } from '../../pages/Privacy';
import { SupportPage } from '../../pages/Support';
import { ContactPage } from '../../pages/Contact';
import { LandingPage } from '../../pages/Landing';
import { App } from '../../App';

function AppRouter() {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<LandingPage />} />
      <Route path="/auth" element={<AuthPage />} />
      
      {/* Protected Main App Route */}
      <Route path="/dashboard" element={
        <ProtectedRoute>
          <App />
        </ProtectedRoute>
      } />

      {/* Protected Feature Routes */}
      <Route path="/payments" element={
        <ProtectedRoute>
          <PaymentsPage />
        </ProtectedRoute>
      } />
      <Route path="/payment-success" element={
        <ProtectedRoute>
          <PaymentSuccess />
        </ProtectedRoute>
      } />

      {/* Information Pages - Public */}
      <Route path="/about" element={<AboutPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/support" element={<SupportPage />} />
      <Route path="/contact" element={<ContactPage />} />

      {/* Catch all route - Redirect to dashboard if authenticated, otherwise to landing */}
      <Route path="*" element={
        <ProtectedRoute>
          <Navigate to="/dashboard" replace />
        </ProtectedRoute>
      } />
    </Routes>
  );
}

export default AppRouter; 