export const endpoints = {
  car: {
    years: '/api/years',
    makes: '/api/makes',
    models: '/api/models',
    description: '/api/car-description',
    advice: '/api/car-advice',
    recalls: '/api/recalls',
    analysis: '/api/analyze'
  },
  auth: {
    login: '/api/auth/login',
    register: '/api/auth/register',
    logout: '/api/auth/logout'
  },
  payment: {
    createCheckout: '/api/create-checkout-session',
    webhook: '/api/webhook'
  }
}; 