import { TabsContent } from "../../../../ui/tabs";

export function SentimentTab({ analysis }) {
  return (
    <TabsContent value="sentiment" className="mt-6">
      <div className="flex items-center gap-6">
        <div className="flex h-24 w-24 items-center justify-center rounded-full bg-primary">
          <span className="text-3xl font-bold text-primary-foreground">
            {analysis?.sentiment?.score}
          </span>
        </div>
        <div className="space-y-2">
          <h3 className="font-semibold text-lg">Sentiment Analysis</h3>
          <p className="text-muted-foreground">{analysis?.sentiment?.explanation}</p>
        </div>
      </div>
    </TabsContent>
  );
} 