import { TabsContent } from "../../../../ui/tabs";
import { 
  GaugeCircle, 
  Car, 
  Radio, 
  Package, 
  Fuel, 
  Shield,
  Cog
} from 'lucide-react';

export function SpecSheet({ specifications }) {
  if (!specifications) return null;

  const { engine, interior, cargoSpace, fuelEconomy, safetyFeatures, wheels } = specifications;

  const sections = [
    {
      title: "Engine & Performance",
      icon: <GaugeCircle className="h-5 w-5 text-primary" />,
      items: [
        { label: "Type", value: engine?.type },
        { label: "Horsepower", value: `${engine?.horsepower} HP` },
        { label: "Torque", value: engine?.torque },
        { label: "Transmission", value: engine?.transmission },
        { label: "Drivetrain", value: Array.isArray(engine?.drivetrain) ? engine.drivetrain.join(", ") : engine?.drivetrain }
      ]
    },
    {
      title: "Interior Features",
      icon: <Car className="h-5 w-5 text-primary" />,
      items: interior?.features?.map(feature => ({ 
        label: "", 
        value: feature 
      })) || []
    },
    {
      title: "Audio & Connectivity",
      icon: <Radio className="h-5 w-5 text-primary" />,
      items: [
        ...(interior?.audioSystem?.map(system => ({ 
          label: "", 
          value: system 
        })) || []),
        { label: "Connectivity", value: "" }, // Section divider
        ...(interior?.connectivity?.map(feature => ({ 
          label: "", 
          value: feature 
        })) || [])
      ]
    },
    {
      title: "Cargo Space",
      icon: <Package className="h-5 w-5 text-primary" />,
      items: [
        { label: "Behind Second Row", value: cargoSpace?.behindSecondRow },
        { label: "With Seats Down", value: cargoSpace?.withSeatsDown }
      ]
    },
    {
      title: "Fuel Economy",
      icon: <Fuel className="h-5 w-5 text-primary" />,
      items: [
        { label: "City", value: fuelEconomy?.city },
        { label: "Highway", value: fuelEconomy?.highway },
        { label: "AWD Adjustment", value: fuelEconomy?.awdAdjustment }
      ]
    },
    {
      title: "Safety Features",
      icon: <Shield className="h-5 w-5 text-primary" />,
      items: safetyFeatures?.map(feature => ({ 
        label: "", 
        value: feature 
      })) || []
    },
    {
      title: "Wheels",
      icon: <Cog className="h-5 w-5 text-primary" />,
      items: wheels?.map(wheel => ({ 
        label: "", 
        value: wheel 
      })) || []
    }
  ];

  return (
    <TabsContent value="specsheet" className="mt-4">
      <div className="space-y-6">
        {sections.map((section, index) => (
          <div key={index} className="bg-muted/50 p-4 rounded-lg space-y-4">
            <div className="flex items-center gap-2">
              {section.icon}
              <h3 className="font-semibold text-lg">
                {section.title}
              </h3>
            </div>
            <div className="space-y-3">
              {section.items
                .filter(item => item.value || item.label === "Connectivity") // Keep section dividers
                .map((item, idx) => (
                  <div key={idx} className="flex flex-col sm:flex-row sm:items-center sm:justify-between py-2 border-b border-border/50 last:border-0">
                    {item.label ? (
                      // For items with labels (like specifications)
                      <>
                        <span className="text-sm font-medium text-muted-foreground mb-1 sm:mb-0">
                          {item.label}
                        </span>
                        <span className="text-sm font-medium break-words max-w-full sm:max-w-[60%] text-right">
                          {item.value}
                        </span>
                      </>
                    ) : item.label === "Connectivity" ? (
                      // For section divider
                      <span className="text-sm font-medium text-primary">
                        Connectivity
                      </span>
                    ) : (
                      // For feature lists
                      <span className="text-sm">
                        {item.value}
                      </span>
                    )}
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </TabsContent>
  );
} 