import React from 'react';
import { TabsContent } from "../../../../ui/tabs";
import { Car, Settings } from 'lucide-react';

export function TrimInfo({ trimData }) {
  if (!trimData || !Array.isArray(trimData)) return null;

  return (
    <TabsContent value="trim" className="mt-4">
      <div className="space-y-6">
        {trimData.map((trim, index) => (
          <div 
            key={index} 
            className="bg-muted/50 p-4 rounded-lg space-y-4"
          >
            <div className="flex items-center gap-2">
              <Car className="h-5 w-5 text-primary" />
              <h3 className="font-semibold text-lg">
                {trim.name}
              </h3>
            </div>

            {trim.features && trim.features.length > 0 && (
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <Settings className="h-4 w-4 text-muted-foreground" />
                  <h4 className="font-medium">Key Features</h4>
                </div>
                <ul className="ml-6 space-y-1 text-muted-foreground">
                  {trim.features.map((feature, idx) => (
                    <li key={idx} className="list-disc">
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </TabsContent>
  );
} 