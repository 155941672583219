import { Button } from "../../../../ui/button";
import { Card, CardHeader, CardContent, CardFooter } from "../../../../ui/card";

export function TokenPackage({ pkg, onPurchase, loading }) {
  return (
    <Card className="flex flex-col">
      <CardHeader>
        <h3 className="text-xl font-bold">{pkg.tokens} Tokens</h3>
        <div className="text-2xl font-bold text-primary">
          ${(pkg.price / 100).toFixed(2)}
        </div>
        <p className="text-sm text-muted-foreground">
          ${((pkg.price / 100) / pkg.tokens).toFixed(2)} per token
        </p>
      </CardHeader>
      <CardContent className="flex-grow">
        {/* Add package features/benefits here if needed */}
      </CardContent>
      <CardFooter>
        <Button 
          className="w-full"
          onClick={() => onPurchase(pkg)}
          disabled={loading}
        >
          {loading ? 'Processing...' : 'Purchase'}
        </Button>
      </CardFooter>
    </Card>
  );
} 