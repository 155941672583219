import React from 'react';
import { PageLayout } from '../../components/layout/PageLayout';

export function PrivacyPage() {
  return (
    <PageLayout>
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl">
          <h1 className="text-4xl font-bold tracking-tight sm:text-6xl mb-10">
            Privacy Policy
          </h1>
          
          <div className="space-y-8">
            <section>
              <h2 className="text-2xl font-bold mb-4">Data Collection</h2>
              <p className="text-muted-foreground">
                We collect information necessary to provide our vehicle analysis services,
                including user account details and vehicle search history. This data helps
                us improve our services and provide personalized experiences.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold mb-4">Data Usage</h2>
              <p className="text-muted-foreground">
                Your data is used to provide vehicle analysis services, improve our AI models,
                and enhance user experience. We never sell personal information to third parties.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold mb-4">Data Protection</h2>
              <p className="text-muted-foreground">
                We implement industry-standard security measures to protect your personal
                information and ensure the safety of your data.
              </p>
            </section>
          </div>
        </div>
      </div>
    </PageLayout>
  );
} 