import React from 'react';
import { Link } from 'react-router-dom';
import { Car } from 'lucide-react';

export function PublicHeader() {
  return (
    <header className="border-b bg-background">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center">
          <Link 
            to="/" 
            className="flex items-center gap-2 text-xl font-bold text-foreground hover:text-primary transition-colors"
          >
            <Car className="h-6 w-6" />
            AutoPulse
          </Link>
        </div>
      </div>
    </header>
  );
} 