import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "../../../ui/card";
import { Accordion } from "../../../ui/accordion";
import { useRecallData } from '../../../../hooks/analysis/useRecallData';
import { RecallLoading } from './components/RecallLoading';
import { RecallItem } from './components/RecallItem';

export function RecallData({ selectedYear, selectedMake, selectedModel }) {
  const { data, isLoading, error } = useRecallData(
    selectedYear,
    selectedMake,
    selectedModel
  );

  if (!selectedYear || !selectedMake || !selectedModel) {
    return null;
  }

  if (isLoading) {
    return <RecallLoading />;
  }

  if (error) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Recall Information</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="rounded-lg bg-destructive/10 p-4 text-destructive">
            <p>{error}</p>
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!data || data.length === 0) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Recall Information</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-muted-foreground">No recalls found for this vehicle.</p>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Recall Information</CardTitle>
      </CardHeader>
      <CardContent>
        <Accordion type="single" collapsible className="space-y-2">
          {data.map((recall, index) => (
            <RecallItem key={index} recall={recall} />
          ))}
        </Accordion>
      </CardContent>
    </Card>
  );
} 