import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "../../../ui/card";
import { Button } from "../../../ui/button";
import { useAnalysisData } from '../../../../hooks/analysis/useAnalysisData';
import { useTokens } from '../../../../hooks/payments/useTokens';
import { LoadingSpinner } from '../../../common/LoadingSpinner';
import { transformAnalysisData } from '../../../../services/analysis/analysisService';
import { AnalysisTabs } from './components/AnalysisTabs';
import { TokenConfirmDialog } from './components/TokenConfirmDialog';
import { tokenService } from '../../../../services/supabase/tokenService';

export function AnalysisResults({ selectedYear, selectedMake, selectedModel }) {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const { tokenBalance } = useTokens();
  const { 
    structuredAnalysis: rawAnalysis,
    isLoading,
    error,
    handleAnalyze,
    clearAnalysis
  } = useAnalysisData();

  const analysis = rawAnalysis ? transformAnalysisData(rawAnalysis) : null;

  // Check if user has access when selections change
  useEffect(() => {
    const checkAccess = async () => {
      if (selectedYear?.value && selectedMake?.value && selectedModel?.value) {
        const access = await tokenService.checkAnalysisAccess(
          selectedYear.value,
          selectedMake.value,
          selectedModel.value
        );
        setHasAccess(access);
      }
    };
    checkAccess();
  }, [selectedYear, selectedMake, selectedModel]);

  useEffect(() => {
    clearAnalysis();
  }, [selectedYear, selectedMake, selectedModel, clearAnalysis]);

  const handleAnalyzeClick = () => {
    setShowConfirmDialog(true);
  };

  const handleConfirmAnalysis = async () => {
    setShowConfirmDialog(false);
    await handleAnalyze(selectedYear, selectedMake, selectedModel);
  };

  if (!selectedYear || !selectedMake || !selectedModel) {
    return null;
  }

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Analyzing Vehicle</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col items-center justify-center py-6 space-y-4">
            <div className="flex flex-col items-center space-y-2">
              <LoadingSpinner size="lg" />
              <p className="text-muted-foreground animate-pulse-slow">
                Gathering insights about {selectedYear.text} {selectedMake.text} {selectedModel.text}
              </p>
            </div>
            <div className="space-y-2 text-sm text-muted-foreground">
              <p className="animate-pulse-slow">• Searching discussions</p>
              <p className="animate-pulse-delay-1">• Finding common issues</p>
              <p className="animate-pulse-delay-2">• Compiling insights</p>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Analysis Results</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="rounded-lg bg-destructive/10 p-4 text-destructive">
            <p>{error}</p>
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!analysis) {
    return (
      <>
        <Card>
          <CardHeader>
            <CardTitle>Analysis Results</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col items-center justify-center py-6 text-center">
              <p className="mb-4 text-muted-foreground">
                {hasAccess 
                  ? "You already have access to this analysis. Click analyze to view the results."
                  : "No analysis available. Click analyze to get insights about this vehicle."
                }
              </p>
              <Button 
                onClick={handleAnalyzeClick}
                className="w-full max-w-sm"
              >
                {hasAccess ? "View Analysis" : "Analyze Vehicle (1 Token)"}
              </Button>
              {!hasAccess && (
                <p className="mt-2 text-sm text-muted-foreground">
                  Your balance: {tokenBalance} tokens
                </p>
              )}
            </div>
          </CardContent>
        </Card>

        <TokenConfirmDialog
          open={showConfirmDialog}
          onOpenChange={setShowConfirmDialog}
          onConfirm={handleConfirmAnalysis}
          tokenBalance={tokenBalance}
          loading={isLoading}
          hasAccess={hasAccess}
        />
      </>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Analysis Results</CardTitle>
      </CardHeader>
      <CardContent>
        <AnalysisTabs analysis={analysis} />
      </CardContent>
    </Card>
  );
}

export default AnalysisResults; 