import { useState, useEffect, useCallback } from 'react';
import { tokenService } from '../../services/supabase/tokenService';
import { useAuth } from '../../contexts/AuthContext';

export function useTokens() {
  const [tokenBalance, setTokenBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  const fetchTokenBalance = useCallback(async () => {
    if (!user) return;
    
    try {
      setLoading(true);
      const balance = await tokenService.getTokenBalance();
      setTokenBalance(balance);
      setError(null);
    } catch (error) {
      console.error('Error fetching token balance:', error);
      setError('Failed to load token balance');
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchTokenBalance();
  }, [fetchTokenBalance]);

  const checkTokenAccess = useCallback(async (year, make, model) => {
    try {
      const hasAccess = await tokenService.checkAnalysisAccess(year, make, model);
      if (!hasAccess) {
        const balance = await tokenService.getTokenBalance();
        if (balance < 1) {
          throw new Error('Insufficient tokens. Please purchase more tokens to continue.');
        }
      }
      return hasAccess;
    } catch (error) {
      console.error('Error checking token access:', error);
      throw error;
    }
  }, []);

  const purchaseAnalysis = useCallback(async (year, make, model) => {
    try {
      await tokenService.purchaseAnalysis(year, make, model);
      await fetchTokenBalance(); // Refresh balance after purchase
    } catch (error) {
      console.error('Error purchasing analysis:', error);
      throw error;
    }
  }, [fetchTokenBalance]);

  return {
    tokenBalance,
    loading,
    error,
    checkTokenAccess,
    purchaseAnalysis,
    refreshBalance: fetchTokenBalance
  };
} 