import { supabase } from './supabaseClient';

export const tokenService = {
  async getTokenBalance() {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      const { data, error } = await supabase
        .from('user_profiles')
        .select('tokens')
        .eq('id', user.id)
        .single();

      if (error) throw error;
      return data.tokens;
    } catch (error) {
      console.error('Error getting token balance:', error);
      throw error;
    }
  },

  async checkAnalysisAccess(year, make, model) {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      // Check for valid access in sentiment_analysis_purchases
      const { data, error } = await supabase
        .from('sentiment_analysis_purchases')
        .select('*')
        .eq('user_id', user.id)
        .eq('car_year', year)
        .eq('car_make', make)
        .eq('car_model', model)
        .gte('expires_at', new Date().toISOString()) // Only get non-expired purchases
        .order('expires_at', { ascending: false }) // Get the latest expiration date first
        .limit(1);

      if (error) throw error;
      
      // If we found a valid purchase that hasn't expired, return true
      return data && data.length > 0;
      
    } catch (error) {
      console.error('Error checking analysis access:', error);
      return false;
    }
  },

  async purchaseAnalysis(year, make, model) {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      // Start a transaction
      const { data: profile, error: profileError } = await supabase
        .from('user_profiles')
        .select('tokens')
        .eq('id', user.id)
        .single();

      if (profileError) throw profileError;
      if (profile.tokens < 1) throw new Error('Insufficient tokens');

      // Calculate expiration date (e.g., 30 days from now)
      const expiresAt = new Date();
      expiresAt.setDate(expiresAt.getDate() + 30);

      // Record access
      const { error: accessError } = await supabase
        .from('sentiment_analysis_purchases')
        .insert({
          user_id: user.id,
          car_year: year,
          car_make: make,
          car_model: model,
          tokens_spent: 1,
          created_at: new Date().toISOString(),
          expires_at: expiresAt.toISOString()
        });

      if (accessError) throw accessError;

      // Deduct token
      const { error: updateError } = await supabase
        .from('user_profiles')
        .update({ tokens: profile.tokens - 1 })
        .eq('id', user.id);

      if (updateError) throw updateError;

      return true;
    } catch (error) {
      console.error('Error purchasing analysis:', error);
      throw error;
    }
  },

  async createCheckoutSession({ priceId, tokens, amount }) {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          priceId,
          tokens,
          amount,
          userId: user.id
        }),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      throw error;
    }
  }
}; 