import { useState, useEffect } from 'react';
import { carService } from '../../services/car/carService';

export function useCarDescription(selectedYear, selectedMake, selectedModel) {
  const [description, setDescription] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDescription = async () => {
      if (!selectedYear || !selectedMake || !selectedModel) {
        setDescription(null);
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        const data = await carService.getDescription(
          selectedYear,
          selectedMake,
          selectedModel
        );
        setDescription(data);
      } catch (err) {
        console.error('Error in useCarDescription:', err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDescription();
  }, [selectedYear, selectedMake, selectedModel]);

  return {
    description,
    isLoading,
    error
  };
} 