import { TokenPackage } from './TokenPackage';

const TOKEN_PACKAGES = [
  { id: 'token_10', tokens: 10, price: 999 },
  { id: 'token_25', tokens: 25, price: 1999 },
  { id: 'token_50', tokens: 50, price: 3499 },
];

export function PackageGrid({ onPurchase, loading }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {TOKEN_PACKAGES.map((pkg) => (
        <TokenPackage
          key={pkg.id}
          pkg={pkg}
          onPurchase={onPurchase}
          loading={loading}
        />
      ))}
    </div>
  );
} 