import { useState, useCallback, useEffect } from 'react';
import { carService } from '../../services/car/carService';

export function useRecallData(selectedYear, selectedMake, selectedModel) {
  const [recallData, setRecallData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchRecallData = useCallback(async () => {
    if (!selectedYear?.value || !selectedMake?.value || !selectedModel?.value) {
      return;
    }

    setIsLoading(true);
    try {
      const data = await carService.getRecalls(selectedYear, selectedMake, selectedModel);
      setRecallData(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching recall data:', error);
      setRecallData([]);
      setError('Unable to fetch recall data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [selectedYear, selectedMake, selectedModel]);

  useEffect(() => {
    fetchRecallData();
  }, [fetchRecallData]);

  return {
    data: recallData,
    isLoading,
    error,
    refetch: fetchRecallData
  };
} 