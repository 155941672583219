import { useState, useEffect } from 'react';
import { carService } from '../../services/car/carService';

export function useCarAdvice(selectedYear, selectedMake, selectedModel) {
  const [advice, setAdvice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('issues');

  useEffect(() => {
    const fetchAdvice = async () => {
      if (!selectedYear?.text || !selectedMake?.text || !selectedModel?.text) {
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        const data = await carService.getAdvice(
          selectedYear,
          selectedMake,
          selectedModel
        );
        setAdvice(data);
      } catch (error) {
        console.error('Error fetching car advice:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAdvice();
  }, [selectedYear, selectedMake, selectedModel]);

  return {
    advice,
    isLoading,
    error,
    activeTab,
    setActiveTab
  };
} 