import React from 'react';
import { PageLayout } from '../../components/layout/PageLayout';
import { Button } from '../../components/ui/button';
import { Card } from '../../components/ui/card';

export function SupportPage() {
  return (
    <PageLayout>
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl">
          <h1 className="text-4xl font-bold tracking-tight sm:text-6xl mb-10">
            Support Center
          </h1>
          
          <div className="space-y-8">
            <section>
              <h2 className="text-2xl font-bold mb-6">Frequently Asked Questions</h2>
              <div className="space-y-4">
                <Card className="p-6">
                  <h3 className="font-semibold mb-2">How do tokens work?</h3>
                  <p className="text-muted-foreground">
                    Each vehicle analysis costs one token. Once purchased, you'll have access
                    to the analysis results for 30 days.
                  </p>
                </Card>
                
                <Card className="p-6">
                  <h3 className="font-semibold mb-2">What if I need a refund?</h3>
                  <p className="text-muted-foreground">
                    Please contact our support team within 24 hours of purchase if you
                    experience any issues with your token purchase.
                  </p>
                </Card>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-bold mb-6">Need More Help?</h2>
              <div className="space-y-4">
                <Button className="w-full">Contact Support Team</Button>
                <p className="text-sm text-muted-foreground text-center">
                  Our team typically responds within 24 hours
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </PageLayout>
  );
} 