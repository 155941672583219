import { TabsContent } from "../../../../ui/tabs";

export function ComparisonTab({ analysis }) {
  return (
    <TabsContent value="comparison" className="mt-6">
      <div className="space-y-6">
        <div className="bg-muted/50 p-4 rounded-lg space-y-2">
          <h3 className="font-semibold text-lg">Market Comparison</h3>
          <p className="text-muted-foreground">{analysis?.comparison?.comparison_analysis}</p>
        </div>

        <div className="bg-muted/50 p-4 rounded-lg space-y-2">
          <h3 className="font-semibold text-lg">Recommendation</h3>
          <p className="text-muted-foreground">{analysis?.recommendation?.recommendation}</p>
        </div>
      </div>
    </TabsContent>
  );
} 