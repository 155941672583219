import React from 'react';
import { PageLayout } from '../../components/layout/PageLayout';
import { Button } from '../../components/ui/button';
import { useNavigate } from 'react-router-dom';
import { 
  BarChart, 
  Users, 
  FileText 
} from 'lucide-react';

const features = [
  {
    name: 'AI-Powered Analysis',
    description: 'Our advanced AI analyzes thousands of user reviews and discussions to provide comprehensive insights.',
    icon: BarChart
  },
  {
    name: 'Real User Experiences',
    description: "Get insights from real owners and enthusiasts who have hands-on experience with the vehicles you're interested in.",
    icon: Users
  },
  {
    name: 'Comprehensive Reports',
    description: 'Get detailed reports covering reliability, common issues, maintenance costs, and overall satisfaction.',
    icon: FileText
  },
];

export function LandingPage() {
  const navigate = useNavigate();

  return (
    <PageLayout>
      <div className="relative isolate">
        {/* Background gradient */}
        <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
          <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-primary to-secondary opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" />
        </div>

        {/* Hero section */}
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h1 className="text-4xl font-bold tracking-tight sm:text-6xl">
              Make Informed Car Decisions with AI-Powered Insights
            </h1>
            <p className="mt-6 text-lg leading-8 text-muted-foreground">
              AutoPulse analyzes thousands of user experiences to provide you with comprehensive
              insights about any vehicle you're interested in.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Button 
                size="lg"
                onClick={() => navigate('/auth')}
              >
                Get Started
              </Button>
              <Button 
                variant="outline" 
                size="lg"
                onClick={() => navigate('/about')}
              >
                Learn More
              </Button>
            </div>
          </div>
        </div>

        {/* Features section */}
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold leading-7 text-primary">
              Analyze Smarter
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">
              Everything you need to make the right choice
            </p>
            <p className="mt-6 text-lg leading-8 text-muted-foreground">
              Get comprehensive insights about any vehicle, powered by real user experiences
              and advanced AI analysis.
            </p>
          </div>

          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7">
                    <feature.icon className="h-5 w-5 flex-none text-primary" aria-hidden="true" />
                    {feature.name}
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-muted-foreground">
                    <p className="flex-auto">{feature.description}</p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </PageLayout>
  );
} 