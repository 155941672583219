import { AccordionItem, AccordionTrigger, AccordionContent } from "../../../../ui/accordion";

export function RecallItem({ recall }) {
  return (
    <AccordionItem value={recall.NHTSACampaignNumber || recall.nhtsaCampaignNumber}>
      <AccordionTrigger className="text-left">
        {recall.Component || recall.component || 'Recall Information'}
      </AccordionTrigger>
      <AccordionContent>
        <div className="space-y-2 text-sm">
          {(recall.Component || recall.component) && (
            <p><span className="font-medium">Component:</span> {recall.Component || recall.component}</p>
          )}
          {(recall.Summary || recall.summary) && (
            <p><span className="font-medium">Summary:</span> {recall.Summary || recall.summary}</p>
          )}
          {(recall.Consequence || recall.consequence) && (
            <p><span className="font-medium">Consequence:</span> {recall.Consequence || recall.consequence}</p>
          )}
          {(recall.Remedy || recall.remedy) && (
            <p><span className="font-medium">Remedy:</span> {recall.Remedy || recall.remedy}</p>
          )}
          {(recall.Manufacturer || recall.manufacturer) && (
            <p><span className="font-medium">Manufacturer:</span> {recall.Manufacturer || recall.manufacturer}</p>
          )}
          {(recall.NHTSACampaignNumber || recall.nhtsaCampaignNumber) && (
            <p><span className="font-medium">NHTSA Campaign Number:</span> {recall.NHTSACampaignNumber || recall.nhtsaCampaignNumber}</p>
          )}
          {(recall.ReportReceivedDate || recall.reportReceivedDate) && (
            <p><span className="font-medium">Report Received Date:</span> {recall.ReportReceivedDate || recall.reportReceivedDate}</p>
          )}
          {(recall.parkIt || recall.parkOutside) && (
            <div className="mt-2 space-y-1">
              {recall.parkIt && (
                <p className="text-destructive font-medium">⚠️ Park It: Vehicle should not be driven</p>
              )}
              {recall.parkOutside && (
                <p className="text-destructive font-medium">⚠️ Park Outside: Vehicle should be parked outdoors</p>
              )}
            </div>
          )}
          {(recall.Notes || recall.notes) && (
            <p className="mt-2"><span className="font-medium">Notes:</span> {recall.Notes || recall.notes}</p>
          )}
        </div>
      </AccordionContent>
    </AccordionItem>
  );
} 