import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SuccessMessage } from './components/SuccessMessage';

export function PaymentSuccess() {
  const navigate = useNavigate();

  useEffect(() => {
    // Optionally refresh token balance or user data here
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-md mx-auto">
        <SuccessMessage onReturn={() => navigate('/')} />
      </div>
    </div>
  );
} 