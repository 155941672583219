import React from 'react';

function ErrorMessage({ message }) {
  if (!message) return null;

  return (
    <div className="bg-destructive/10 text-destructive p-4 rounded-lg" role="alert">
      <strong>Error: </strong>
      <span>{message}</span>
    </div>
  );
}

export default ErrorMessage;
