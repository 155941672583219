import { LoadingSpinner } from "../../../../common/LoadingSpinner";

export function AdviceLoading() {
  return (
    <section className="bg-white rounded-lg shadow-sm border border-gray-200">
      <div className="p-4 border-b border-gray-200">
        <h3 className="text-lg font-semibold text-gray-900">
          Loading Vehicle Information
        </h3>
      </div>
      <div className="p-6">
        <div className="flex flex-col items-center justify-center space-y-4">
          <div className="flex flex-col items-center space-y-2">
            <LoadingSpinner />
            <p className="text-gray-600 animate-pulse-slow">
              Gathering advice and common issues
            </p>
          </div>
          <div className="space-y-2 text-sm text-gray-500">
            <p className="animate-pulse-slow">• Collecting maintenance data</p>
            <p className="animate-pulse-delay-1">• Finding common issues</p>
            <p className="animate-pulse-delay-2">• Compiling buying tips</p>
          </div>
        </div>
      </div>
    </section>
  );
} 