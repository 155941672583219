import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import TokenDisplay from '../TokenDisplay';
import { Button } from "../../ui/button";

export function Header() {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    await signOut();
    navigate('/auth');
  };

  return (
    <header className="bg-background border-b">
      <div className="container mx-auto px-4 h-16 flex items-center justify-between">
        <Link to="/dashboard" className="text-xl font-bold text-primary">
          AutoPulse
        </Link>

        {user && (
          <div className="flex items-center gap-4">
            <TokenDisplay />
            <Button 
              variant="outline" 
              size="sm"
              onClick={() => navigate('/payments')}
            >
              Buy Tokens
            </Button>
            <Button 
              variant="ghost" 
              size="sm"
              onClick={handleSignOut}
            >
              Sign Out
            </Button>
          </div>
        )}
      </div>
    </header>
  );
} 