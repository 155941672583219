import React from 'react';
import { Card, CardContent } from "../../../ui/card";
import { SelectInput } from "../../../ui/select-input";
import ErrorMessage from '../../../common/ErrorMessage';
import { useCarData } from '../../../../hooks/car/useCarData';

export function SearchSection({ onCarSelect }) {
  const {
    years,
    makes,
    models,
    error,
    selectedYear,
    selectedMake,
    selectedModel,
    populateMakes,
    populateModels,
    updateModelSelection
  } = useCarData();

  const handleYearSelect = (year) => {
    populateMakes(year);
    onCarSelect(year, null, null);
  };

  const handleMakeSelect = (make) => {
    populateModels(selectedYear, make);
    onCarSelect(selectedYear, make, null);
  };

  const handleModelSelect = (model) => {
    updateModelSelection(model);
    onCarSelect(selectedYear, selectedMake, model);
  };

  return (
    <Card>
      <CardContent className="p-6">
        <div className="space-y-4">
          <SelectInput
            placeholder="Select Year"
            options={years}
            value={selectedYear}
            onChange={handleYearSelect}
          />

          <SelectInput
            placeholder="Select Make"
            options={makes}
            value={selectedMake}
            onChange={handleMakeSelect}
            isDisabled={!selectedYear}
          />

          <SelectInput
            placeholder="Select Model"
            options={models}
            value={selectedModel}
            onChange={handleModelSelect}
            isDisabled={!selectedMake}
          />
        </div>
        {error && <ErrorMessage message={error} className="mt-4" />}
      </CardContent>
    </Card>
  );
} 