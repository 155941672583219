import React from 'react';
import { Header } from './components/common/Header';
import { SearchSection } from './components/features/car/SearchSection';
import { CarSelected } from './components/features/car/CarSelected';
import { ResultsSection } from './components/features/results/ResultsSection';
import { MainLayout } from './components/layouts/MainLayout';

export function App() {
  const [selectedCar, setSelectedCar] = React.useState({
    selectedYear: null,
    selectedMake: null,
    selectedModel: null
  });

  const handleCarSelect = (year, make, model) => {
    setSelectedCar({
      selectedYear: year,
      selectedMake: make,
      selectedModel: model
    });
  };

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <MainLayout>
        <div className="grid gap-8 md:grid-cols-[400px,1fr]">
          <div className="space-y-6">
            <SearchSection onCarSelect={handleCarSelect} />
            {selectedCar.selectedYear && (
              <CarSelected 
                selectedYear={selectedCar.selectedYear}
                selectedMake={selectedCar.selectedMake}
                selectedModel={selectedCar.selectedModel}
              />
            )}
          </div>
          {selectedCar.selectedYear && (
            <ResultsSection selectedCar={selectedCar} />
          )}
        </div>
      </MainLayout>
    </div>
  );
}

export default App;
