import React from 'react';
import { CarAdvice } from '../../car/CarAdvice';
import { RecallData } from '../../analysis/RecallData';
import { AnalysisResults } from '../../analysis/AnalysisResults';
import { Card, CardContent } from "../../../ui/card";

export function ResultsSection({ selectedCar }) {
  const { selectedYear, selectedMake, selectedModel } = selectedCar;

  if (!selectedYear || !selectedMake || !selectedModel) {
    return null;
  }

  return (
    <Card className="bg-background">
      <CardContent className="p-6 space-y-6">
        <CarAdvice 
          selectedYear={selectedYear}
          selectedMake={selectedMake}
          selectedModel={selectedModel}
        />
        
        <RecallData 
          selectedYear={selectedYear}
          selectedMake={selectedMake}
          selectedModel={selectedModel}
        />
        
        <AnalysisResults 
          selectedYear={selectedYear}
          selectedMake={selectedModel}
          selectedModel={selectedModel}
        />
      </CardContent>
    </Card>
  );
} 