import React from 'react';
import SignIn from '../SignIn';
import SignUp from '../SignUp';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../../../ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../ui/tabs";
import { PageLayout } from '../../../layout/PageLayout';

export function AuthPage() {
  return (
    <PageLayout>
      <div className="flex items-center justify-center py-24 px-4">
        <Card className="w-full max-w-md">
          <CardHeader>
            <CardTitle className="text-2xl font-bold text-center">AutoPulse</CardTitle>
            <CardDescription className="text-center">
              Your Car Research Companion
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Tabs defaultValue="signin" className="w-full">
              <TabsList className="grid w-full grid-cols-2 mb-6">
                <TabsTrigger value="signin">Sign In</TabsTrigger>
                <TabsTrigger value="signup">Sign Up</TabsTrigger>
              </TabsList>
              <TabsContent value="signin" className="mt-0">
                <SignIn />
              </TabsContent>
              <TabsContent value="signup" className="mt-0">
                <SignUp />
              </TabsContent>
            </Tabs>
          </CardContent>
        </Card>
      </div>
    </PageLayout>
  );
} 