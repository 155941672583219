import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../../services/supabase/supabaseClient';
import { useAuth } from '../../contexts/AuthContext';

function TokenDisplay() {
  const [tokenBalance, setTokenBalance] = useState(0);
  const { user } = useAuth();

  const fetchTokenBalance = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('user_profiles')
        .select('tokens')
        .eq('id', user.id)
        .single();

      if (error) throw error;
      setTokenBalance(data.tokens);
    } catch (error) {
      console.error('Error fetching token balance:', error);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchTokenBalance();
      const subscription = supabase
        .channel('user_profiles_changes')
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'user_profiles',
            filter: `id=eq.${user.id}`,
          },
          (payload) => {
            setTokenBalance(payload.new.tokens);
          }
        )
        .subscribe();

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [user, fetchTokenBalance]);

  return (
    <div className="flex items-center gap-2 px-3 py-1 bg-primary/10 rounded-full">
      <span className="text-sm font-medium text-primary">Tokens:</span>
      <span className="text-sm font-bold text-primary">{tokenBalance}</span>
    </div>
  );
}

export default TokenDisplay; 