import React from 'react';
import { PageLayout } from '../../components/layout/PageLayout';

export function TermsPage() {
  return (
    <PageLayout>
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl">
          <h1 className="text-4xl font-bold tracking-tight sm:text-6xl mb-10">
            Terms of Service
          </h1>
          
          <div className="space-y-8">
            <section>
              <h2 className="text-2xl font-bold mb-4">1. Acceptance of Terms</h2>
              <p className="text-muted-foreground">
                By accessing and using CarWise, you agree to be bound by these Terms of Service.
                Our service provides AI-powered vehicle analysis and insights based on user data and experiences.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold mb-4">2. Token System</h2>
              <p className="text-muted-foreground">
                CarWise operates on a token-based system. Each analysis costs one token,
                and access to analysis results expires after 30 days from the purchase date.
                Tokens are non-refundable once used.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-bold mb-4">3. User Responsibilities</h2>
              <p className="text-muted-foreground">
                Users are responsible for maintaining the confidentiality of their account
                credentials and for all activities that occur under their account.
              </p>
            </section>
          </div>
        </div>
      </div>
    </PageLayout>
  );
} 