import React from 'react';
import { PageLayout } from '../../components/layout/PageLayout';

export function AboutPage() {
  return (
    <PageLayout>
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl">
          <h1 className="text-4xl font-bold tracking-tight sm:text-6xl">
            About AutoPulse
          </h1>
          <p className="mt-6 text-lg leading-8 text-muted-foreground">
            AutoPulse is your trusted companion in making informed decisions about vehicles.
            Using advanced AI technology, we analyze thousands of user experiences and
            reviews to provide you with comprehensive insights about any car you're
            interested in.
          </p>
          
          <div className="mt-10 space-y-8">
            <div>
              <h2 className="text-2xl font-bold">Our Mission</h2>
              <p className="mt-4 text-muted-foreground">
                To empower car buyers with data-driven insights and real user experiences,
                making the car-buying process more transparent and informed.
              </p>
            </div>
            
            <div>
              <h2 className="text-2xl font-bold">How It Works</h2>
              <p className="mt-4 text-muted-foreground">
                AutoPulse uses advanced AI to analyze user discussions, reviews, and
                experiences across various platforms. We process this information to
                provide you with actionable insights about reliability, common issues,
                and overall satisfaction for any vehicle you're interested in.
              </p>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
} 