import React from 'react';
import { CardHeader, CardTitle } from "../../../../ui/card";

export function SelectedVehicleHeader({ year, make, model }) {
  return (
    <CardHeader>
      <CardTitle>
        {year} {make} {model}
      </CardTitle>
    </CardHeader>
  );
} 