import { Tabs, TabsList, TabsTrigger } from "../../../../ui/tabs";
import { 
  BarChart, 
  ThumbsUp, 
  MessageCircle,
  BarChart2
} from 'lucide-react';
import { OverviewTab } from './OverviewTab';
import { SentimentTab } from './SentimentTab';
import { TopicsTab } from './TopicsTab';
import { ComparisonTab } from './ComparisonTab';

export function AnalysisTabs({ analysis }) {
  return (
    <Tabs defaultValue="overview" className="w-full">
      <TabsList className="grid w-full grid-cols-4">
        <TabsTrigger value="overview" className="flex items-center justify-center gap-2">
          <BarChart className="hidden md:inline h-4 w-4" />
          <span>Overview</span>
        </TabsTrigger>
        <TabsTrigger value="sentiment" className="flex items-center justify-center gap-2">
          <ThumbsUp className="hidden md:inline h-4 w-4" />
          <span>Sentiment</span>
        </TabsTrigger>
        <TabsTrigger value="topics" className="flex items-center justify-center gap-2">
          <MessageCircle className="hidden md:inline h-4 w-4" />
          <span>Topics</span>
        </TabsTrigger>
        <TabsTrigger value="comparison" className="flex items-center justify-center gap-2">
          <BarChart2 className="hidden md:inline h-4 w-4" />
          <span>Compare</span>
        </TabsTrigger>
      </TabsList>
      
      <OverviewTab analysis={analysis} />
      <SentimentTab analysis={analysis} />
      <TopicsTab analysis={analysis} />
      <ComparisonTab analysis={analysis} />
    </Tabs>
  );
} 