import { TabsContent } from "../../../../ui/tabs";

export function TopicsTab({ analysis }) {
  return (
    <TabsContent value="topics" className="mt-6">
      <div className="space-y-6">
        {analysis?.popular_topics?.map((topic, index) => (
          <div key={index} className="bg-muted/50 p-4 rounded-lg space-y-2">
            <h3 className="font-semibold text-lg">{topic.topic}</h3>
            <p className="text-muted-foreground">{topic.summary}</p>
          </div>
        ))}
      </div>
    </TabsContent>
  );
} 