import { useState, useCallback } from 'react';
import api from '../../services/api/client';
import { tokenService } from '../../services/supabase/tokenService';

export function useAnalysisData() {
  const [structuredAnalysis, setStructuredAnalysis] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleAnalyze = useCallback(async (selectedYear, selectedMake, selectedModel) => {
    try {
      if (!selectedYear || !selectedMake || !selectedModel) {
        throw new Error('Please select a vehicle first');
      }

      setIsLoading(true);
      setError(null);

      // Check if user already has access
      const hasAccess = await tokenService.checkAnalysisAccess(
        selectedYear.value,
        selectedMake.value,
        selectedModel.value
      );

      if (!hasAccess) {
        // Check token balance and purchase access
        const balance = await tokenService.getTokenBalance();
        if (balance < 1) {
          throw new Error('Insufficient tokens. Please purchase more tokens to continue.');
        }

        await tokenService.purchaseAnalysis(
          selectedYear.value,
          selectedMake.value,
          selectedModel.value
        );
      }

      const response = await api.post('/api/analyze', {
        year: selectedYear.value,
        make: selectedMake.value,
        model: selectedModel.value
      });

      setStructuredAnalysis(response.data);
    } catch (error) {
      console.error('Analysis error:', error);
      setError(error.message);
      setStructuredAnalysis(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const clearAnalysis = useCallback(() => {
    setStructuredAnalysis(null);
    setError(null);
    setIsLoading(false);
  }, []);

  return {
    structuredAnalysis,
    isLoading,
    error,
    handleAnalyze,
    clearAnalysis
  };
} 