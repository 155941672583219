import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../../../ui/dialog";
import { Button } from "../../../../ui/button";
import { useNavigate } from 'react-router-dom';

export function TokenConfirmDialog({ 
  open, 
  onOpenChange, 
  onConfirm, 
  tokenBalance, 
  loading,
  hasAccess 
}) {
  const navigate = useNavigate();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {hasAccess ? "View Analysis" : "Confirm Analysis"}
          </DialogTitle>
          <DialogDescription className="space-y-2">
            {hasAccess ? (
              <p>
                You already have access to this analysis. Would you like to view it now?
              </p>
            ) : (
              <>
                <p>
                  This analysis will cost 1 token. You currently have {tokenBalance} tokens.
                </p>
                {tokenBalance < 1 && (
                  <p className="text-destructive">
                    You don't have enough tokens. Please purchase more to continue.
                  </p>
                )}
              </>
            )}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="flex gap-2">
          {!hasAccess && tokenBalance < 1 ? (
            <>
              <Button 
                variant="outline" 
                onClick={() => onOpenChange(false)}
              >
                Cancel
              </Button>
              <Button 
                onClick={() => navigate('/payments')}
              >
                Purchase Tokens
              </Button>
            </>
          ) : (
            <>
              <Button 
                variant="outline" 
                onClick={() => onOpenChange(false)}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button 
                onClick={onConfirm}
                disabled={loading}
              >
                {loading ? "Processing..." : (hasAccess ? "View Analysis" : "Proceed with Analysis")}
              </Button>
            </>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
} 