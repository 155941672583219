import api from '../api/client';
import { endpoints } from '../api/endpoints';

export const analysisService = {
  async analyze(year, make, model) {
    const response = await api.post(endpoints.car.analysis, {
      year: year.value,
      make: make.value,
      model: model.value
    });
    return response.data;
  }
};

export const transformAnalysisData = (analysisData) => {
  return {
    summary: analysisData.summary,
    sentiment: {
      score: analysisData.sentiment?.score || 0,
      explanation: analysisData.sentiment?.explanation || ''
    },
    pros: analysisData.pros || [],
    cons: analysisData.cons || [],
    popular_topics: analysisData.popular_topics || [],
    performance: {
      analysis: analysisData.performance?.analysis || ''
    },
    comfort_and_interior: {
      analysis: analysisData.comfort_and_interior?.analysis || ''
    },
    technology_and_features: {
      overview: analysisData.technology_and_features?.overview || ''
    },
    comparison: {
      comparison_analysis: analysisData.comparison?.comparison_analysis || ''
    },
    recommendation: {
      recommendation: analysisData.recommendation?.recommendation || ''
    }
  };
}; 