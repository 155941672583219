import React, { useState } from 'react';
import { useCarDescription } from '../../../../hooks/car/useCarDescription';
import { DescriptionLoading } from './components/DescriptionLoading';
import { SpecSheet } from './components/SpecSheet';
import { TrimInfo } from './components/TrimInfo';
import { CardContent } from "../../../ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../ui/tabs";

export function CarDescription({ selectedYear, selectedMake, selectedModel }) {
  const { description, isLoading, error } = useCarDescription(selectedYear, selectedMake, selectedModel);
  const [activeTab, setActiveTab] = useState('specsheet');

  if (!selectedYear || !selectedMake || !selectedModel) {
    return null;
  }

  if (isLoading) {
    return <DescriptionLoading />;
  }

  if (error) {
    return (
      <div className="rounded-lg bg-destructive/10 p-4 text-destructive">
        <p>{error}</p>
      </div>
    );
  }

  if (!description?.vehicle) {
    return null;
  }

  return (
    <CardContent className="p-6">
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        <TabsList>
          <TabsTrigger value="specsheet">Specifications</TabsTrigger>
          <TabsTrigger value="trim">Trim Level</TabsTrigger>
        </TabsList>
        <TabsContent value="specsheet">
          <SpecSheet specifications={description.vehicle.specifications} />
        </TabsContent>
        <TrimInfo trimData={description.vehicle.trims} />
      </Tabs>
    </CardContent>
  );
}

export default CarDescription; 