import React from 'react';
import { Footer } from '../../layout/Footer';

export function MainLayout({ children }) {
  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-grow container mx-auto px-4 py-8">
        {children}
      </main>
      <Footer />
    </div>
  );
}

export default MainLayout; 