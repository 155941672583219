import { TabsContent } from "../../../../ui/tabs";
import { ThumbsUp, ThumbsDown } from 'lucide-react';

export function OverviewTab({ analysis }) {
  return (
    <TabsContent value="overview" className="mt-6 space-y-6">
      <div className="prose max-w-none">
        <p className="text-muted-foreground">{analysis?.summary}</p>
      </div>
      
      <div className="grid gap-6 md:grid-cols-2">
        <div className="space-y-4">
          <h3 className="font-semibold text-lg flex items-center gap-2">
            <ThumbsUp className="h-5 w-5 text-green-500" />
            Pros
          </h3>
          <ul className="space-y-2">
            {analysis?.pros?.map((pro, index) => (
              <li key={index} className="flex items-start gap-2">
                <span className="text-green-500">•</span>
                <div>
                  <span className="font-medium">{pro.aspect}</span>
                  <p className="text-muted-foreground">{pro.explanation}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        
        <div className="space-y-4">
          <h3 className="font-semibold text-lg flex items-center gap-2">
            <ThumbsDown className="h-5 w-5 text-red-500" />
            Cons
          </h3>
          <ul className="space-y-2">
            {analysis?.cons?.map((con, index) => (
              <li key={index} className="flex items-start gap-2">
                <span className="text-red-500">•</span>
                <div>
                  <span className="font-medium">{con.aspect}</span>
                  <p className="text-muted-foreground">{con.explanation}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </TabsContent>
  );
} 