import React from 'react';
import { useCarAdvice } from '../../../../hooks/car/useCarAdvice';
import { AdviceLoading } from './components/AdviceLoading';
import { AdviceContent } from './components/AdviceContent';

export function CarAdvice({ selectedYear, selectedMake, selectedModel }) {
  const {
    advice,
    isLoading,
    error,
    activeTab,
    setActiveTab
  } = useCarAdvice(selectedYear, selectedMake, selectedModel);

  if (!selectedYear || !selectedMake || !selectedModel) {
    return null;
  }

  if (isLoading) {
    return <AdviceLoading />;
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-red-600">
        Error loading advice: {error}
      </div>
    );
  }

  if (!advice) {
    return null;
  }

  return (
    <section className="bg-white rounded-lg shadow-sm border border-gray-200">
      <h3 className="text-lg font-semibold text-gray-900 p-4 border-b border-gray-200">
        Buying Guide & Common Issues
      </h3>
      <AdviceContent 
        advice={advice}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </section>
  );
} 