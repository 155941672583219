import { useState, useCallback, useEffect } from 'react';
import { carService } from '../../services/car/carService';

export function useCarData() {
  const [years, setYears] = useState([]);
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMake, setSelectedMake] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [lastSelected, setLastSelected] = useState({
    year: null,
    make: null,
    model: null
  });
  const [error, setError] = useState(null);

  const populateYears = useCallback(async () => {
    try {
      const data = await carService.getYears();
      setYears(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching years:', error);
      setError('An error occurred while fetching years. Please try again later.');
      setYears([]);
    }
  }, []);

  useEffect(() => {
    populateYears();
  }, [populateYears]);

  const populateMakes = useCallback(async (year) => {
    try {
      setSelectedYear(year);
      setSelectedMake(null);
      setSelectedModel(null);
      setMakes([]);
      setModels([]);
      
      if (!year) return;
      
      const data = await carService.getMakes(year);
      setMakes(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching makes:', error);
      setError('An error occurred while fetching makes. Please try again later.');
      setMakes([]);
    }
  }, []);

  const populateModels = useCallback(async (year, make) => {
    try {
      setSelectedMake(make);
      if (!year || !make) {
        setModels([]);
        return;
      }
      
      const data = await carService.getModels(year, make);
      setModels(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching models:', error);
      setError('An error occurred while fetching models. Please try again later.');
      setModels([]);
      setSelectedModel(null);
    }
  }, []);

  const updateModelSelection = useCallback((model) => {
    setSelectedModel(model);
    if (model) {
      const newLastSelected = {
        year: {
          value: selectedYear.value,
          text: selectedYear.text
        },
        make: {
          value: selectedMake.value,
          text: selectedMake.text
        },
        model: {
          value: model.value,
          text: model.text
        }
      };
      setLastSelected(newLastSelected);
      
      // Reset selections after a brief delay
      setTimeout(() => {
        setSelectedYear(null);
        setSelectedMake(null);
        setSelectedModel(null);
        setMakes([]);
        setModels([]);
        populateYears(); // Repopulate the years dropdown
      }, 500); // Half second delay
    }
  }, [selectedYear, selectedMake, populateYears]);

  useEffect(() => {
    if (lastSelected.year && lastSelected.make && lastSelected.model) {
    }
  }, [lastSelected]);

  const resetSelections = useCallback(() => {
    setSelectedYear(null);
    setSelectedMake(null);
    setSelectedModel(null);
    setMakes([]);
    setModels([]);
    
    populateYears();
  }, [populateYears]);

  return {
    years,
    makes,
    models,
    selectedYear,
    selectedMake,
    selectedModel,
    lastSelected,
    error,
    populateMakes,
    populateModels,
    updateModelSelection,
    resetSelections,
    populateYears
  };
} 