import { CheckCircle } from 'lucide-react';
import { Button } from "../../../../ui/button";
import { 
  Card, 
  CardHeader, 
  CardContent, 
  CardTitle,
  CardDescription 
} from "../../../../ui/card";

export function SuccessMessage({ onReturn }) {
  return (
    <Card>
      <CardHeader>
        <div className="flex items-center gap-2">
          <CheckCircle className="h-6 w-6 text-green-500" />
          <CardTitle>Payment Successful</CardTitle>
        </div>
        <CardDescription>
          Your tokens have been added to your account
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <Button 
          onClick={onReturn}
          className="w-full"
        >
          Return to Dashboard
        </Button>
      </CardContent>
    </Card>
  );
} 