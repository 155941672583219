import React from 'react';
import { PublicHeader } from '../PublicHeader';
import { Footer } from '../Footer';

export function PageLayout({ children }) {
  return (
    <div className="min-h-screen flex flex-col">
      <PublicHeader />
      <main className="flex-grow">
        {children}
      </main>
      <Footer />
    </div>
  );
} 