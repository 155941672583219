import React from 'react';
import { Card } from "../../../ui/card";
import { SelectedVehicleHeader } from './components/SelectedVehicleHeader';
import { CarDescription } from '../CarDescription';

export function CarSelected({ selectedYear, selectedMake, selectedModel }) {
  if (!selectedYear || !selectedMake || !selectedModel) {
    return null;
  }

  return (
    <Card>
      <SelectedVehicleHeader 
        year={selectedYear.text || selectedYear.value}
        make={selectedMake.text || selectedMake.value}
        model={selectedModel.text || selectedModel.value}
      />
      <CarDescription 
        selectedYear={selectedYear.value}
        selectedMake={selectedMake.value}
        selectedModel={selectedModel.value}
      />
    </Card>
  );
} 