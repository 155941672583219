export function AdviceContent({ advice, activeTab, setActiveTab }) {
  return (
    <div className="p-4">
      <nav className="flex gap-4 border-b border-gray-200 mb-4">
        <button 
          className={`pb-2 px-1 font-medium transition-colors
              ${activeTab === 'issues' 
                  ? 'text-blue-600 border-b-2 border-blue-600' 
                  : 'text-gray-600 hover:text-blue-600'
              }`}
          onClick={() => setActiveTab('issues')}
        >
          Common Issues & Fixes
        </button>
        <button 
          className={`pb-2 px-1 font-medium transition-colors
              ${activeTab === 'shopping' 
                  ? 'text-blue-600 border-b-2 border-blue-600' 
                  : 'text-gray-600 hover:text-blue-600'
              }`}
          onClick={() => setActiveTab('shopping')}
        >
          Shopping Considerations
        </button>
      </nav>

      {activeTab === 'issues' && (
        <div className="space-y-4">
          {advice.issues_and_fixes.map((item, index) => (
            <div key={index} className="bg-gray-50 rounded-lg p-4">
              <div className="font-medium text-gray-900 mb-2">
                <span className="text-blue-600">Issue: </span>
                {item.issue}
              </div>
              <div className="text-gray-700">
                <span className="text-green-600 font-medium">Fix: </span>
                {item.fix}
              </div>
            </div>
          ))}
        </div>
      )}

      {activeTab === 'shopping' && (
        <div className="space-y-4">
          {advice.shopping_considerations.map((item, index) => (
            <div key={index} className="bg-gray-50 rounded-lg p-4">
              <div className="font-medium text-gray-900 mb-2">
                {item.consideration}
              </div>
              <p className="text-gray-700">{item.explanation}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
} 