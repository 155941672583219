import api from '../api/client';
import { endpoints } from '../api/endpoints';

export const carService = {
  // Car Data
  async getYears() {
    const response = await api.get(endpoints.car.years);
    return response.data;
  },

  async getMakes(year) {
    const response = await api.get(endpoints.car.makes, {
      params: { year: year.value }
    });
    return response.data;
  },

  async getModels(year, make) {
    const response = await api.get(endpoints.car.models, {
      params: { 
        year: year.value,
        make: make.value 
      }
    });
    return response.data;
  },

  // Car Description
  async getDescription(year, make, model) {
    try {
      const response = await api.get(endpoints.car.description, {
        params: {
          year: year,
          make: make,
          model: model
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching car description:', error);
      throw error;
    }
  },

  // Car Advice
  async getAdvice(year, make, model) {
    const response = await api.get(endpoints.car.advice, {
      params: {
        year: year.text,
        make: make.text,
        model: model.text
      }
    });

    if (!response.data.issues_and_fixes || !response.data.shopping_considerations) {
      throw new Error('Invalid data format received');
    }

    return response.data;
  },

  // Recalls
  async getRecalls(year, make, model) {
    const response = await api.get(endpoints.car.recalls, {
      params: {
        year: year.value,
        make: make.value,
        model: model.value
      }
    });
    return response.data;
  }
}; 