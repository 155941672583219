import React from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../select";

export function SelectInput({ 
  placeholder, 
  options = [], 
  value, 
  onChange, 
  isDisabled 
}) {
  return (
    <Select
      value={value?.value || ""}
      onValueChange={(val) => {
        const option = options.find(opt => opt.value === val);
        onChange(option);
      }}
      disabled={isDisabled}
    >
      <SelectTrigger className="w-full">
        <SelectValue placeholder={placeholder}>
          {value?.text || placeholder}
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {options.map((option) => (
          <SelectItem key={option.value} value={option.value}>
            {option.text}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
} 